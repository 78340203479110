import React from 'react';
import {graphql} from 'gatsby';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
import Hero from '@components/content/hero.jsx';
import SignUpForm from "@components/form/Form.Signup.jsx";
import Anchor from '@components/content/Anchor';
// Utility imports
import SEO from '@components/utility/SEO.jsx';
import {getImage, withArtDirection, StaticImage} from 'gatsby-plugin-image';


const Register = ({data}) => {
	const heroImage = withArtDirection(
		getImage(data.heroLarge), [
		{
			media: "(max-width: 600px)",
			image: getImage(data.heroSmall)
		},
		{
			media: "(max-width: 1440px)",
			image: getImage(data.heroMedium)
		}
	])
	const signatureImage = getImage(data.signature);

	return(
	<Layout pageClassName="register">
		<SEO title='Enrollment Form | Aurinia Alliance&reg;' description='Enroll now to learn more about the Aurinia Alliance&reg; program and to get started on your Aurinia treatment journey. Fill out your information below and a Nurse Case Manager will contact you directly.' />
		<Hero heading={['Experience all that ', <br className='mobile-only'/>,'Aurinia',<span key={'nbsp'}>&nbsp;</span>,'Alliance', <sup key='super'>&reg;</sup>, ' has to offer']} background={heroImage} signature={signatureImage} signatureDisc={[<p key='name'>Nicole M., RN</p>,<p key='ncm'>Nurse Case Manager</p>]}>
			<p>Enroll now to get the benefits of Aurinia Alliance. Just fill out your information below and a Nurse Case Manager will contact you directly.</p>
		</Hero>
		<section id="SignUp">
			<div className="wrapper">
				<p>* = required field</p>
				<SignUpForm></SignUpForm>
			</div>
		</section>
		<section className="cta-row cta-row--navy-blue">
			<div className="wrapper">
				<h2>Are you a healthcare professional looking to help your patient register&nbsp;with Aurinia Alliance?</h2>
				<Anchor linkClass={'btn btn--white'} isExternal={true} link="https://lupkynispro.com/starting-patients/" requiresHCPModal={true}>GET STARTED HERE</Anchor>
			</div>
		</section>
	</Layout>
	)
};

export default Register;

export const pageQuery = graphql`
  query RegisterHeroQuery {
    heroSmall: file(relativePath: {eq: "hero/enroll-hero--s.png"}) {
		childImageSharp {
		  gatsbyImageData(width:375)
		}
	},
	heroMedium: file(relativePath: {eq: "hero/enroll-hero--m.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1440)
		}
	},	
	heroLarge: file(relativePath: {eq: "hero/enroll-hero--l.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1500)
		}
	},
	signature: file(relativePath: {eq: "hero/enroll-signature.png"}) {
		childImageSharp {
		  gatsbyImageData(width:134)
		}
	},
}
`
